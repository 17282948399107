@import "global-nav.scss";
@import "global-form.scss";
@import "global-table.scss";
@import "global-other.scss";
@import "global-vendor-tweaks.scss";

// The PrimeNg OverlayPanel component comes with a default 20px padding on all sides. I tried several
// ways to override that styling and the way that works is to use the "styleClass" input and apply
// the .global-overlaypanel-padding to it. Now it should override that style within that component,
// instead of everywhere p-overlaypanel-content class is used. This appears to be the same approach
// used by PrimeNg on their table filter icons when they use their own OverlayPanel component, granted
// I couldn't find their source code on that and had to fiddle until I found a solution, but they
// definitely override the padding with !important to get it to go away.
//  Things that didn't work:
//  - using the 'style' input on the OverlayPanel component
//  - Declaring the style in styles.css file at root level
//  - creating a wrapper div around the OverlayPanel component with the global
//    css class
//  - searching documentation for a flag to just turn off padding
.global-overlaypanel-padding .p-overlaypanel-content {
  padding: 0 !important;
}

// Added for using the image-group-render component in the text-find component. The table has striped rows
// and the dropzone background color is always white. In this situation we just make the dropzone background
// transparent so it will match the table stripes.
// My original ideas that weren't good:
// - track row index and pass it to image group render component, and then it sets a bool for if the
// background should be grey. No good because it's super hard to get the color to match perfectly without
// just using the rgba(0,0,0,0.5) style from bootstrap. If using that style, it doesn't match because then we see
// the grey stripe through the new transparent background we applied and the color is slightly off. If we used
// a non transparent color and matched perfectly, now if bootstrap ever changes styling, we need to come back
// and paint match.
// - trying to use the config to change the style but we'd need to rebuild the config every time the row changes
// and this is not practical, it's expensive and we just want to build the config onInit.
.global-dropzone-background-transparent .dropzone {
  background-color:rgba(0, 0, 0, 0.0);
}
